import React from "react"

const CloseIcon = ({ color = "black", ...rest }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.0304 9.79794L19.0923 3.73608C19.7621 3.0663 19.7621 1.98044 19.0923 1.31171L18.2842 0.503586C17.6142 -0.166397 16.5283 -0.166397 15.8596 0.503586L9.79794 6.56524L3.73608 0.50233C3.0663 -0.167443 1.98044 -0.167443 1.31171 0.50233L0.50233 1.31045C-0.167443 1.98044 -0.167443 3.0663 0.50233 3.73503L6.56524 9.79794L0.503586 15.8596C-0.166397 16.5296 -0.166397 17.6154 0.503586 18.2842L1.31171 19.0923C1.98148 19.7621 3.06735 19.7621 3.73608 19.0923L9.79794 13.0304L15.8596 19.0923C16.5296 19.7621 17.6154 19.7621 18.2842 19.0923L19.0923 18.2842C19.7621 17.6142 19.7621 16.5283 19.0923 15.8596L13.0304 9.79794Z"
        fill={color}
      />
    </svg>
  )
}

export default CloseIcon
