import React from "react"

const EuroIcon = ({ color = "#2571FE", ...rest }) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.5395 15.0431C14.4516 16.2237 13.0588 16.8739 11.6175 16.8739C9.25529 16.8739 7.20992 15.1625 6.2473 12.6886H12.8088C13.3778 12.6886 13.8391 12.2127 13.8391 11.6257C13.8391 11.0387 13.3778 10.5628 12.8088 10.5628H5.72374C5.68322 10.2153 5.66109 9.86078 5.66109 9.4998C5.66109 9.09263 5.68888 8.6935 5.74072 8.30398H12.8088C13.3778 8.30398 13.8391 7.82806 13.8391 7.24107C13.8391 6.65407 13.3778 6.17811 12.8088 6.17811H6.30211C7.28537 3.77664 9.29805 2.12568 11.6175 2.12568C13.0588 2.12568 14.4516 2.77598 15.5395 3.95639C15.9311 4.3816 16.5829 4.39907 16.9959 3.99467C17.4084 3.59009 17.4248 2.91731 17.0332 2.49188C15.5526 0.884991 13.6294 6.10352e-05 11.6178 6.10352e-05C8.183 6.10352e-05 5.24694 2.57372 4.10759 6.17841H1.34669C0.777713 6.17841 0.316406 6.65433 0.316406 7.24133C0.316406 7.82833 0.777713 8.30425 1.34669 8.30425H3.66611C3.62437 8.69649 3.60049 9.09483 3.60049 9.50006C3.60049 9.85972 3.61871 10.2137 3.65203 10.563H1.34669C0.777713 10.563 0.316406 11.0389 0.316406 11.6259C0.316406 12.2129 0.777713 12.6889 1.34669 12.6889H4.06669C5.17498 16.3632 8.1407 19.0001 11.6175 19.0001C13.6293 19.0001 15.5521 18.1149 17.0326 16.5083C17.4245 16.0827 17.4084 15.41 16.9957 15.0055C16.5834 14.6009 15.9311 14.6178 15.5395 15.0431Z"
        fill={color}
      />
    </svg>
  )
}

export default EuroIcon
