import { PropsWithChildren } from "react"
import "./modal.scss"

type ModalProps = {
  open: boolean
  onClose: () => void
  className?: string
  closable?: boolean
}

const Modal = ({
  open,
  onClose,
  className = "",
  children,
  closable = true,
}: PropsWithChildren<ModalProps>) => {
  return (
    <div
      className={`backdrop ${open ? "visible" : "hidden"}`}
      onClick={closable ? onClose : undefined}
    >
      <div
        className={`content ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  )
}

export default Modal
