import React from "react"

const LoaderIcon = ({ color = "black", ...rest }) => {
  return (
    <svg
      width="72"
      height="75"
      viewBox="0 0 72 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M37.7342 17.3113C42.5146 17.3113 46.3899 13.4361 46.3899 8.65566C46.3899 3.87527 42.5146 0 37.7342 0C32.9538 0 29.0786 3.87527 29.0786 8.65566C29.0786 13.4361 32.9538 17.3113 37.7342 17.3113Z"
        fill={color}
      />
      <path
        d="M37.7342 74.0029C40.6021 74.0029 42.9271 71.6779 42.9271 68.81C42.9271 65.9421 40.6021 63.6172 37.7342 63.6172C34.8663 63.6172 32.5414 65.9421 32.5414 68.81C32.5414 71.6779 34.8663 74.0029 37.7342 74.0029Z"
        fill={color}
      />
      <path
        d="M16.4658 25.2537C20.7684 25.2537 24.2564 21.7657 24.2564 17.463C24.2564 13.1604 20.7684 9.67236 16.4658 9.67236C12.1631 9.67236 8.67511 13.1604 8.67511 17.463C8.67511 21.7657 12.1631 25.2537 16.4658 25.2537Z"
        fill={color}
      />
      <path
        d="M59.0026 64.3251C61.3928 64.3251 63.3305 62.3875 63.3305 59.9973C63.3305 57.6071 61.3928 55.6694 59.0026 55.6694C56.6124 55.6694 54.6748 57.6071 54.6748 59.9973C54.6748 62.3875 56.6124 64.3251 59.0026 64.3251Z"
        fill={color}
      />
      <path
        d="M7.65563 45.6572C11.479 45.6572 14.5785 42.5577 14.5785 38.7344C14.5785 34.911 11.479 31.8115 7.65563 31.8115C3.83225 31.8115 0.732788 34.911 0.732788 38.7344C0.732788 42.5577 3.83225 45.6572 7.65563 45.6572Z"
        fill={color}
      />
      <path
        d="M67.8099 42.1915C69.7208 42.1915 71.27 40.6424 71.27 38.7315C71.27 36.8206 69.7208 35.2715 67.8099 35.2715C65.899 35.2715 64.3499 36.8206 64.3499 38.7315C64.3499 40.6424 65.899 42.1915 67.8099 42.1915Z"
        fill={color}
      />
      <path
        d="M12.1829 55.7198C9.81534 58.0873 9.81534 61.918 12.1829 64.2856C14.5476 66.6531 18.3839 66.6531 20.7487 64.2856C23.1162 61.918 23.1162 58.0873 20.7487 55.7198C18.3839 53.3494 14.5504 53.327 12.1829 55.7198Z"
        fill={color}
      />
      <path
        d="M58.9998 20.0582C60.433 20.0582 61.5948 18.8964 61.5948 17.4632C61.5948 16.03 60.433 14.8682 58.9998 14.8682C57.5666 14.8682 56.4048 16.03 56.4048 17.4632C56.4048 18.8964 57.5666 20.0582 58.9998 20.0582Z"
        fill={color}
      />
    </svg>
  )
}

export default LoaderIcon
