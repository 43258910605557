import React from "react"

const DoubleArrowDown = ({ color = "#FFF", ...rest }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.0164 33.1634L33.1593 19.0206C34.2747 17.9051 34.2747 16.096 33.1593 14.9806C32.0438 13.8651 30.2347 13.8651 29.1192 14.9806L16.9978 27.102L4.87639 14.9806C3.76093 13.8651 1.95182 13.8651 0.836361 14.9806C-0.279102 16.096 -0.279102 17.9051 0.836361 19.0206L14.9792 33.1634C16.0919 34.2789 17.9009 34.2789 19.0164 33.1634ZM19.8728 16.1583L31.7069 4.9301C32.8945 3.80186 32.8945 1.97442 31.7069 0.846181C30.5192 -0.28206 28.5926 -0.28206 27.4036 0.846181L17.7211 10.0332L8.03874 0.846181C6.85106 -0.28206 4.92312 -0.28206 3.73543 0.846181C2.54775 1.97442 2.54775 3.80186 3.73543 4.9301L15.5695 16.1583C16.7571 17.2865 18.6837 17.2865 19.8728 16.1583Z"
        fill="white"
      />
    </svg>
  )
}

export default DoubleArrowDown
