import React from "react"
import Tooltip from "../subComponents/Tooltip"
import {
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from "@stripe/react-stripe-js"
import {
  StripeCardNumberElementChangeEvent,
  StripeCardNumberElementOptions,
} from "@stripe/stripe-js"

type CardNumberElementInputProps = {
  cardNumberIsValid: 0 | 1 | -1
  setCardNumberIsValid: React.Dispatch<React.SetStateAction<0 | 1 | -1>>
}

const CardNumberElementInput = ({
  cardNumberIsValid,
  setCardNumberIsValid,
}: CardNumberElementInputProps) => {
  const elements = useElements()

  const cardNumberElementOptions: StripeCardNumberElementOptions = {
    placeholder: "",
    style: {
      base: {
        fontSize: "16px",
        fontWeight: 300,
      },
      invalid: {
        color: "#000",
      },
    },
  }

  const handleChange = (event: StripeCardNumberElementChangeEvent) => {
    if (event.complete) {
      setCardNumberIsValid(1)
      const expiry = elements?.getElement(CardExpiryElement)
      if (expiry) {
        expiry.focus()
      }
    } else if (event.error) {
      setCardNumberIsValid(-1)
    } else {
      setCardNumberIsValid(0)
    }
  }

  return (
    <Tooltip
      text="Votre numéro carte n'est pas valide."
      visibleTooltip={cardNumberIsValid === -1}
    >
      <div
        className="form-group"
        onClick={() => {
          const cardNumber = elements?.getElement(CardNumberElement)
          if (cardNumber) {
            cardNumber.focus()
          }
        }}
        style={{
          border:
            cardNumberIsValid === -1
              ? "1px solid rgb(255, 0, 0)"
              : cardNumberIsValid === 1
              ? "1px solid #02bd02"
              : "1px solid #fff",
        }}
      >
        <div className="form-input">
          <label htmlFor="card">Numéro de carte</label>
          <CardNumberElement
            className="input"
            onChange={handleChange}
            options={cardNumberElementOptions}
          />
        </div>
      </div>
    </Tooltip>
  )
}

export default CardNumberElementInput
