const ChevronRightIcon = ({ color = "#2571FE", ...rest }) => {
  return (
    <svg
      width="13"
      height="21"
      viewBox="0 0 13 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.96708 10.1093L1.21904 17.4811C1.0057 17.6836 0.886018 17.9567 0.881811 18.2497C0.877602 18.5428 0.989395 18.8191 1.19682 19.0279L1.85693 19.6928C2.06402 19.9019 2.34318 20.0195 2.6424 20.0238C2.94163 20.0281 3.22371 19.9186 3.43689 19.7155L12.6622 10.9387C12.8762 10.7353 12.9957 10.461 12.9991 10.1677C13.0042 9.87311 12.8927 9.59587 12.6845 9.38619L3.72333 0.356928C3.51607 0.147752 3.23725 0.0302403 2.93786 0.0259414C2.63864 0.0216449 2.35655 0.131103 2.1432 0.334239L1.46445 0.979845C1.02278 1.40005 1.01281 2.09429 1.44224 2.52684L8.96708 10.1093Z"
        fill={color}
      />
    </svg>
  )
}

export default ChevronRightIcon
