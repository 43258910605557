import React, { FC, useState } from "react"
import "./tooltip.scss"

interface TooltipProps {
  children: React.ReactNode
  text: string
  visibleTooltip?: boolean
  forFixed?: boolean
}

const Tooltip: FC<TooltipProps> = ({
  children,
  text,
  visibleTooltip = false,
  forFixed = false,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    if (visibleTooltip) {
      setShowTooltip(true)
    }
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  const handleClick = () => {
    setShowTooltip(!showTooltip)
  }

  const handleTouchStart = () => {
    if (visibleTooltip) {
      setShowTooltip(true)
    }
  }

  const handleTouchEnd = () => {
    setShowTooltip(false)
  }

  return (
    <div
      className={"tooltip" + (forFixed ? " forFixed" : "")}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
    >
      {children}
      {showTooltip && (
        <div className={"tooltip-text" + (forFixed ? " forFixed" : "")}>
          {text}
        </div>
      )}
    </div>
  )
}

export default Tooltip
