import React, { PropsWithChildren } from "react"

import "./button.scss"
import Spinner from "../subComponents/Spinner"

type ButtonProps = {
  loading?: boolean
  disabled?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button = ({
  className,
  loading,
  disabled,
  children,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  return (
    <button
      className={`button ${className}`}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <Spinner /> : children}
    </button>
  )
}

export default Button
