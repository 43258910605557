import "./spinner.scss"

const Spinner = () => {
  return (
    <div id="wave">
      <span className="dot"></span>
      <span className="dot"></span>
      <span className="dot"></span>
    </div>
  )
}

export default Spinner
