const ShippingIcon = ({ color = "#1C50D0", ...rest }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M32.4993 13.3333H28.3327V9.99996C28.3327 8.16663 26.8327 6.66663 24.9993 6.66663H4.99935C3.16602 6.66663 1.66602 8.16663 1.66602 9.99996V25C1.66602 26.8333 3.16602 28.3333 4.99935 28.3333C4.99935 31.1 7.23268 33.3333 9.99935 33.3333C12.766 33.3333 14.9993 31.1 14.9993 28.3333H24.9993C24.9993 31.1 27.2327 33.3333 29.9993 33.3333C32.766 33.3333 34.9993 31.1 34.9993 28.3333H36.666C37.5827 28.3333 38.3327 27.5833 38.3327 26.6666V21.1166C38.3327 20.4 38.0993 19.7 37.666 19.1166L33.8327 14C33.516 13.5833 33.016 13.3333 32.4993 13.3333ZM9.99935 30C9.08268 30 8.33268 29.25 8.33268 28.3333C8.33268 27.4166 9.08268 26.6666 9.99935 26.6666C10.916 26.6666 11.666 27.4166 11.666 28.3333C11.666 29.25 10.916 30 9.99935 30ZM32.4993 15.8333L35.766 20H28.3327V15.8333H32.4993ZM29.9993 30C29.0827 30 28.3327 29.25 28.3327 28.3333C28.3327 27.4166 29.0827 26.6666 29.9993 26.6666C30.916 26.6666 31.666 27.4166 31.666 28.3333C31.666 29.25 30.916 30 29.9993 30Z"
        fill={color}
      />
    </svg>
  )
}

export default ShippingIcon
