import { createContext } from "react"
import { ComparatorState } from "./types"

const urlParams = new URLSearchParams(window.location.search)

const stateParam = urlParams.get("state")
const stateJson = stateParam ? JSON.parse(stateParam) : null

// Initialize the state with the parameters from the URL
export const initialisedComparatorState: ComparatorState = {
  source: stateJson?.source ?? localStorage.getItem("source"),
  source_url: stateJson?.source_url ?? localStorage.getItem("source_url"),
  from:
    typeof stateJson?.from === "string"
      ? { name: stateJson.from }
      : stateJson?.from,
  to:
    typeof stateJson?.to === "string" ? { name: stateJson.to } : stateJson?.to,
  departureDate: stateJson?.departureDate,
  returnDate: stateJson?.returnDate,
  passengers: stateJson?.passengers,
  price: stateJson?.price,
}

export const ComparatorContext = createContext<{
  comparatorState: ComparatorState | null
  setComparatorState: React.Dispatch<
    React.SetStateAction<ComparatorState | null>
  >
}>({
  comparatorState: initialisedComparatorState,
  setComparatorState: () => null,
})
