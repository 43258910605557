const SpaIcon = ({ color = "#1C50D0", ...rest }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M25.8168 16.0501C25.5502 12.0168 24.1002 8.06678 21.4168 4.78345C20.7335 3.95011 19.4835 3.95011 18.8002 4.78345C16.0502 8.08345 14.5168 12.0334 14.1835 16.0501C16.3168 17.1834 18.2835 18.6501 20.0002 20.4334C21.7168 18.6668 23.6835 17.2001 25.8168 16.0501ZM14.9835 20.4668C14.7502 20.3001 14.4835 20.1501 14.2335 19.9834C14.4835 20.1668 14.7502 20.3001 14.9835 20.4668ZM25.6835 20.0501C25.4668 20.2001 25.2335 20.3168 25.0168 20.4834C25.2335 20.3168 25.4668 20.2001 25.6835 20.0501ZM20.0002 25.7501C16.7502 20.8001 11.4335 17.3668 5.28352 16.7668C4.21685 16.6668 3.33352 17.5501 3.43352 18.6168C4.18352 26.6168 9.51685 33.2501 16.7335 35.8334C17.7835 36.2168 18.8835 36.5001 20.0168 36.6834C21.1502 36.4834 22.2335 36.2001 23.3002 35.8334C30.5168 33.2501 35.8502 26.6334 36.6002 18.6168C36.7002 17.5501 35.8002 16.6668 34.7502 16.7668C28.5668 17.3668 23.2502 20.8001 20.0002 25.7501Z"
        fill={color}
      />
    </svg>
  )
}

export default SpaIcon
