import * as amplitude from "@amplitude/analytics-browser"
import { SourceType, SourceTypeEnum } from "../context/comparator/types"

type initProps = {
  userId?: string
  deviceId?: string
}

export const initAmplitude = (source: SourceType, initProps?: initProps) => {
  const userId = initProps?.userId ?? undefined
  const deviceId = initProps?.deviceId ?? undefined

  let apiKey = ""

  switch (source) {
    case SourceTypeEnum.Train:
    case SourceTypeEnum.Bus:
    case SourceTypeEnum.Avion:
      apiKey = process.env.REACT_APP_AMPLITUDE_KEY_MALIN as string
      break
    case SourceTypeEnum.TrainEasy:
    case SourceTypeEnum.BusEasy:
    case SourceTypeEnum.AvionEasy:
      apiKey = process.env.REACT_APP_AMPLITUDE_KEY_EASY as string
      break
  }

  return amplitude.init(apiKey as string, userId, {
    defaultTracking: {
      sessions: false,
      pageViews: false,
      formInteractions: false,
      fileDownloads: true,
    },
    deviceId: deviceId,
  })
}

export const sendAmplitudeTrack = (eventName: string, data?: any) => {
  return amplitude.track(eventName, {
    website: window.location.origin,
    source: localStorage.getItem("source") || "unknown",
    ...(data || {}),
  })
}

export const getAmplitudeInstance = () => {
  return {
    deviceId: amplitude.getDeviceId() ?? null,
    userId: amplitude.getUserId() ?? null,
  }
}

export const setAmplitudeUserId = (userId: string) => {
  return amplitude.setUserId(userId)
}

export const setAmplitudeDeviceId = (deviceId: string) => {
  return amplitude.setDeviceId(deviceId)
}
