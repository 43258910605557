const AssignmentIcon = ({ color = "#1C50D0", ...rest }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M31.6667 4.99996H24.7C24 3.06663 22.1667 1.66663 20 1.66663C17.8333 1.66663 16 3.06663 15.3 4.99996H8.33333C6.5 4.99996 5 6.49996 5 8.33329V31.6666C5 33.5 6.5 35 8.33333 35H31.6667C33.5 35 35 33.5 35 31.6666V8.33329C35 6.49996 33.5 4.99996 31.6667 4.99996ZM20 4.99996C20.9167 4.99996 21.6667 5.74996 21.6667 6.66663C21.6667 7.58329 20.9167 8.33329 20 8.33329C19.0833 8.33329 18.3333 7.58329 18.3333 6.66663C18.3333 5.74996 19.0833 4.99996 20 4.99996ZM20 11.6666C22.7667 11.6666 25 13.9 25 16.6666C25 19.4333 22.7667 21.6666 20 21.6666C17.2333 21.6666 15 19.4333 15 16.6666C15 13.9 17.2333 11.6666 20 11.6666ZM30 31.6666H10V29.3333C10 26 16.6667 24.1666 20 24.1666C23.3333 24.1666 30 26 30 29.3333V31.6666Z"
        fill={color}
      />
    </svg>
  )
}

export default AssignmentIcon
