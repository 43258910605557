import { useMemo, useRef } from "react"

import "./question.scss"

type QuestionProps = {
  question: string
  answer: string
  index: number
  currentActive: number | null
  updateActive: (index: number) => void
}

const Question = ({
  question,
  answer,
  index,
  currentActive,
  updateActive,
}: QuestionProps) => {
  const isCollapsed = useMemo(
    () => index !== currentActive,
    [index, currentActive]
  )

  const contentRef = useRef<HTMLDivElement>(null)

  return (
    <div className="question">
      <button className="question-btn" onClick={() => updateActive(index)}>
        <h4>{question}</h4>
        <div className={`icon-wrapper ${!isCollapsed ? "active" : ""}`}>
          <span className="bar"></span>
        </div>
      </button>

      <div
        ref={contentRef}
        className="collapsed-content"
        style={{
          maxHeight: isCollapsed
            ? "0px"
            : (contentRef?.current?.scrollHeight || 200) + "px",
        }}
      >
        <p
          className="answer-content"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </div>
    </div>
  )
}

export default Question
