const AirplaneIcon = ({ color = "#1C50D0", ...rest }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M35.8006 22.8333L22.5007 14.9999V5.83325C22.5007 4.44992 21.384 3.33325 20.0007 3.33325C18.6173 3.33325 17.5007 4.44992 17.5007 5.83325V14.9999L4.20065 22.8333C3.66732 23.1332 3.33398 23.7166 3.33398 24.3333C3.33398 25.4999 4.45065 26.3333 5.56732 26.0166L17.5007 22.5166V31.6666L13.734 33.9166C13.484 34.0666 13.334 34.3499 13.334 34.6332V35.4666V35.5999C13.334 36.1499 13.8507 36.5499 14.3673 36.4166L19.234 35.1999L20.0007 34.9999L20.634 35.1499L21.334 35.3333L24.5007 36.1333L25.6173 36.4166C26.1507 36.5499 26.6507 36.1499 26.6507 35.5999V34.9832V34.6332C26.6507 34.3333 26.5007 34.0666 26.2507 33.9166L22.5007 31.6666V22.4999L34.434 25.9999C35.5507 26.3333 36.6673 25.4999 36.6673 24.3333C36.6673 23.7166 36.334 23.1332 35.8006 22.8333Z"
        fill={color}
      />
    </svg>
  )
}

export default AirplaneIcon
