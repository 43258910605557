import React from "react"
import Tooltip from "../subComponents/Tooltip"
import { CardCvcElement, useElements } from "@stripe/react-stripe-js"
import {
  StripeCardCvcElementChangeEvent,
  StripeCardCvcElementOptions,
} from "@stripe/stripe-js"

type CardCvcElementInputProps = {
  cardCvcIsValid: 0 | 1 | -1
  setCardCvcIsValid: React.Dispatch<React.SetStateAction<0 | 1 | -1>>
}

const CardCvcElementInput = ({
  cardCvcIsValid,
  setCardCvcIsValid,
}: CardCvcElementInputProps) => {
  const elements = useElements()
  const cardCvcElementOptions: StripeCardCvcElementOptions = {
    placeholder: "",
    style: {
      base: {
        fontSize: "16px",
        fontWeight: 300,
      },
      invalid: {
        color: "#000",
      },
    },
  }

  const handleChange = (event: StripeCardCvcElementChangeEvent) => {
    if (event.complete) {
      setCardCvcIsValid(1)
    } else if (event.error) {
      setCardCvcIsValid(-1)
    } else {
      setCardCvcIsValid(0)
    }
  }

  return (
    <Tooltip
      text="Le code de sécurité est invalide."
      visibleTooltip={cardCvcIsValid === -1}
    >
      <div
        className="form-group"
        onClick={() => {
          const cardCvc = elements?.getElement(CardCvcElement)
          if (cardCvc) {
            cardCvc.focus()
          }
        }}
        style={{
          border:
            cardCvcIsValid === -1
              ? "1px solid rgb(255, 0, 0)"
              : cardCvcIsValid === 1
              ? "1px solid #02bd02"
              : "1px solid #fff",
        }}
      >
        <div className="form-input">
          <label htmlFor="card">CVC</label>
          <CardCvcElement
            className="input"
            onChange={handleChange}
            options={cardCvcElementOptions}
          />
        </div>
      </div>
    </Tooltip>
  )
}

export default CardCvcElementInput
