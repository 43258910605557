import { createContext } from "react"

export interface PaymentState {
  customer: {
    firstname: string
    lastname: string
    phone: string
    email: string
  }
  bearer?: {
    firstname?: string
    lastname?: string
    email?: string
    phone?: string
  }
  error: string | null | undefined
}

export const initialPaymentState: PaymentState = {
  customer: {
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
  },
  error: null,
}

export const PaymentContext = createContext<{
  paymentState: PaymentState
  setPaymentState: React.Dispatch<React.SetStateAction<PaymentState>>
}>({
  paymentState: initialPaymentState,
  setPaymentState: () => null,
})
