import React from "react"

const UserCheckIcon = ({ color = "#2571FE", ...rest }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_2_530)">
        <path
          d="M16.3164 21.0001V19.0001C16.3164 17.9392 15.895 16.9218 15.1448 16.1716C14.3947 15.4215 13.3773 15.0001 12.3164 15.0001H5.31641C4.25554 15.0001 3.23812 15.4215 2.48798 16.1716C1.73783 16.9218 1.31641 17.9392 1.31641 19.0001V21.0001"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.81641 11.0001C11.0255 11.0001 12.8164 9.2092 12.8164 7.00006C12.8164 4.79092 11.0255 3.00006 8.81641 3.00006C6.60727 3.00006 4.81641 4.79092 4.81641 7.00006C4.81641 9.2092 6.60727 11.0001 8.81641 11.0001Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.3164 11.0001L19.3164 13.0001L23.3164 9.00006"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_530">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.316406 6.10352e-05)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UserCheckIcon
