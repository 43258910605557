import React from "react"

const InfoIcon = ({ color = "#06102A", ...rest }) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.4041 0.0424805C7.15691 0.0424805 0.482422 6.71621 0.482422 14.9642C0.482422 23.2113 7.15616 29.8859 15.4041 29.8859C23.6513 29.8859 30.3258 23.2122 30.3258 14.9642C30.3258 6.71709 23.6521 0.0424805 15.4041 0.0424805ZM16.9365 20.8875C16.9365 21.3589 16.249 21.8302 15.4044 21.8302C14.5205 21.8302 13.8921 21.3589 13.8921 20.8875V13.4041C13.8921 12.8541 14.5206 12.4808 15.4044 12.4808C16.249 12.4808 16.9365 12.8541 16.9365 13.4041V20.8875ZM15.4045 10.674C14.501 10.674 13.7939 10.0062 13.7939 9.25972C13.7939 8.51335 14.501 7.86519 15.4045 7.86519C16.2884 7.86519 16.9955 8.51335 16.9955 9.25972C16.9955 10.0062 16.2883 10.674 15.4045 10.674Z"
        fill={color}
      />
    </svg>
  )
}

export default InfoIcon
