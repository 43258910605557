import React, { FC } from "react"
import NavBar from "./NavBar"
import "./layout.scss"
import Footer from "./Footer"
import { useLocation } from "react-router-dom"
import { SourceType } from "../../context/comparator/types"
import { globalConfig } from "../../config"

interface LayoutProps {
  children: React.ReactNode
  withNavBar?: boolean
}

export const Layout: FC<LayoutProps> = ({ children, withNavBar = false }) => {
  const { pathname } = useLocation()
  const source = localStorage.getItem("source") as SourceType
  return (
    <main
      id="global-container"
      className={globalConfig[source].specificClassName}
    >
      {withNavBar && <NavBar />}
      {children}
      {pathname !== "/confirm" && <Footer />}
    </main>
  )
}

export default Layout
