import React from "react"
import Tooltip from "../subComponents/Tooltip"
import {
  CardCvcElement,
  CardExpiryElement,
  useElements,
} from "@stripe/react-stripe-js"
import {
  StripeCardExpiryElementChangeEvent,
  StripeCardExpiryElementOptions,
} from "@stripe/stripe-js"

type CardExpiryElementInputProps = {
  cardExpiryIsValid: 0 | 1 | -1
  setCardExpiryIsValid: React.Dispatch<React.SetStateAction<0 | 1 | -1>>
}

const CardExpiryElementInput = ({
  cardExpiryIsValid,
  setCardExpiryIsValid,
}: CardExpiryElementInputProps) => {
  const elements = useElements()

  const cardExpiryElementOptions: StripeCardExpiryElementOptions = {
    placeholder: "",
    style: {
      base: {
        fontSize: "16px",
        fontWeight: 300,
      },
      invalid: {
        color: "#000",
      },
    },
  }

  const handleChange = (event: StripeCardExpiryElementChangeEvent) => {
    if (event.complete) {
      setCardExpiryIsValid(1)
      const cvc = elements?.getElement(CardCvcElement)
      if (cvc) {
        cvc.focus()
      }
    } else if (event.error) {
      setCardExpiryIsValid(-1)
    } else {
      setCardExpiryIsValid(0)
    }
  }

  return (
    <Tooltip
      text="La date d'expiration est dépassée."
      visibleTooltip={cardExpiryIsValid === -1}
    >
      <div
        className="form-group"
        onClick={() => {
          const cardExpiry = elements?.getElement(CardExpiryElement)
          if (cardExpiry) {
            cardExpiry.focus()
          }
        }}
        style={{
          border:
            cardExpiryIsValid === -1
              ? "1px solid rgb(255, 0, 0)"
              : cardExpiryIsValid === 1
              ? "1px solid #02bd02"
              : "1px solid #fff",
        }}
      >
        <div className="form-input">
          <label htmlFor="card">Date d'expiration</label>
          <CardExpiryElement
            className="input"
            onChange={handleChange}
            options={cardExpiryElementOptions}
          />
        </div>
      </div>
    </Tooltip>
  )
}

export default CardExpiryElementInput
