import { SourceType } from "../context/comparator/types"

export const isValidEmail = (email: string): boolean => {
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return regexEmail.test(email)
}

export const getSubscriptionPrice = () => {
  const source = localStorage.getItem("source") as SourceType

  return source.includes("train")
    ? process.env.REACT_APP_PRICE_TRAIN_SUBSCRIPTION
    : source.includes("bus")
    ? process.env.REACT_APP_PRICE_BUS_SUBSCRIPTION
    : process.env.REACT_APP_PRICE_AVION_SUBSCRIPTION
}
