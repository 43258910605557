import { Award, Eye, HelpCircle, Star, UserCheck } from "react-feather"
import "./skills.scss"
import { SourceType } from "../../context/comparator/types"
import { globalConfig } from "../../config"

const Skills = () => {
  const firstColor = "#EEF3FB"
  const secondColor = "#219EBC"
  const source = localStorage.getItem("source") as SourceType
  const specificClassName = globalConfig[source].specificClassName

  return (
    <div className={`card-container ${specificClassName}`}>
      <div className="card">
        <div className="icon ">
          <Award
            size={24}
            color={source.includes("-easy") ? secondColor : firstColor}
          />
        </div>
        <p className="light w600">Expert en voyage</p>
      </div>
      <div className="card">
        <div className="icon">
          <HelpCircle
            size={24}
            color={source.includes("-easy") ? secondColor : firstColor}
          />
        </div>
        <p className="light w600">Assistance 7J7</p>
      </div>
      <div className="card">
        <div className="icon">
          <Star
            size={24}
            color={source.includes("-easy") ? secondColor : firstColor}
          />
        </div>
        <p className="light w600">Simple et rapide</p>
      </div>
      <div className="card">
        <div className="icon">
          <UserCheck
            size={24}
            color={source.includes("-easy") ? secondColor : firstColor}
          />
        </div>
        <p className="light w600">Contact humain</p>
      </div>
      <div className="card fullwidth">
        <div className="icon">
          <Eye
            size={24}
            color={source.includes("-easy") ? secondColor : firstColor}
          />
        </div>
        <p className="light w600">Prix transparents et fiables</p>
      </div>
    </div>
  )
}

export default Skills
