const TicketIcon = ({ color = "#1C50D0", ...rest }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M33.334 20.0001C33.334 18.7334 34.0506 17.6334 35.1006 17.0667C36.1007 16.5167 36.6673 15.3834 36.6673 14.2334V10.0001C36.6673 8.16675 35.1673 6.66675 33.334 6.66675H6.66732C4.83398 6.66675 3.35065 8.15008 3.35065 9.98341V14.2334C3.35065 15.3834 3.90065 16.5167 4.91732 17.0501C5.96732 17.6334 6.66732 18.7334 6.66732 20.0001C6.66732 21.2667 5.95065 22.3834 4.90065 22.9334C3.90065 23.4834 3.33398 24.6167 3.33398 25.7667V29.5167C3.33398 31.8334 4.83398 33.3334 6.66732 33.3334H33.334C35.1673 33.3334 36.6673 31.8334 36.6673 30.0001V25.7667C36.6673 24.6167 36.1007 23.4834 35.1006 22.9334C34.0506 22.3667 33.334 21.2667 33.334 20.0001ZM24.1673 26.8334L20.0007 24.1667L15.834 26.8501C15.2007 27.2501 14.384 26.6667 14.584 25.9334L15.834 21.1334L12.0007 18.0001C11.4173 17.5167 11.7173 16.5667 12.484 16.5167L17.4173 16.2334L19.2173 11.6501C19.5007 10.9501 20.5007 10.9501 20.7673 11.6501L22.5673 16.2501L27.5007 16.5334C28.2507 16.5834 28.5673 17.5334 27.984 18.0167L24.1507 21.1501L25.4173 25.9167C25.6173 26.6667 24.8006 27.2501 24.1673 26.8334Z"
        fill={color}
      />
    </svg>
  )
}

export default TicketIcon
