const CrownIcon = ({ color = "#EEF3FB", ...rest }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.66602 28.3333H33.3327V29.9999C33.3327 31.8409 31.8403 33.3333 29.9993 33.3333H9.99935C8.1584 33.3333 6.66602 31.8409 6.66602 29.9999V28.3333Z"
        fill={color}
      />
      <path
        d="M4.48966 13.3929L6.66602 25.0001H34.166L35.631 13.2804C35.7152 12.6065 35.0012 12.1203 34.405 12.4455L27.3354 16.3016C26.9565 16.5083 26.4825 16.3914 26.243 16.0323L20.6927 7.70681C20.3629 7.21203 19.6358 7.21203 19.306 7.70681L13.7557 16.0323C13.5162 16.3914 13.0422 16.5083 12.6632 16.3016L5.70776 12.5077C5.09094 12.1713 4.36018 12.7023 4.48966 13.3929Z"
        fill={color}
      />
    </svg>
  )
}

export default CrownIcon
